import { MutationResult } from "@apollo/client";
import { graphql } from "shared/gql";
import {
  TrackEventInput,
  UseTrackImpressionMutation as UseTrackImpressionMutationType,
} from "generated/string_unions/graphql";
import { useRouter } from "shared/hooks/use-router";
import { useMutationWithErrorLogging } from "shared/hooks/useMutationWithErrorLogging";
import { useEffect } from "react";
import { PageName } from "./utils";

const UseTrackImpressionMutation = graphql(/* GraphQL */ `
  mutation UseTrackImpression($input: TrackEventInput!) {
    trackEvent(input: $input) {
      __typename
    }
  }
`);

type RequiredAdditionalData = Omit<
  Exclude<TrackEventInput["pageImpression"], null | undefined>,
  "route" | "urlQueryParams" | "pageName"
> & { pageName: PageName };
/**
 * Track an event in MixPanel. Runs asynchronously and has a low chance of
 * failing without retries due to using raw `POST` under-the-hood. This is a
 * okay tradeoff for a method we'll be calling relatively frequently that does
 * not need to be 100% reliable
 *
 * @param event - The event to track
 * @param data - The payload associated with the event
 */
export function useTrackImpression(
  data: RequiredAdditionalData
): MutationResult<UseTrackImpressionMutationType> {
  const router = useRouter();
  const [mutation, res] = useMutationWithErrorLogging(
    UseTrackImpressionMutation
  );
  useEffect(() => {
    if (router.isReady) {
      const variables = {
        input: {
          pageImpression: {
            route: router.pathname!,
            urlQueryParams: router.query!,
            ...data,
          },
        },
      } as const;
      mutation({
        variables,
      });
    }
  }, [router.isReady]);

  return res;
}
