import parsePhoneNumber from "libphonenumber-js";

export function formatPhoneNumber(phoneNumber: string) {
  if (isValidE164Format(phoneNumber)) {
    return parsePhoneNumber(phoneNumber)?.formatNational() || phoneNumber;
  }

  const fullNumber = phoneNumber.replace(/\D/g, "");
  if (fullNumber.length === 0) return fullNumber;
  if (fullNumber.length < 4) {
    return fullNumber;
  }
  if (fullNumber.length < 7) {
    return `(${fullNumber.slice(0, 3)}) ${fullNumber.slice(3)}`;
  }
  return `(${fullNumber.slice(0, 3)}) ${fullNumber.slice(
    3,
    6
  )}-${fullNumber.slice(6, 10)}`;
}

function isValidE164Format(phoneNumber: string) {
  const regex = /^\+[1-9]\d{10,14}$/;
  return regex.test(phoneNumber);
}

export function toTitleCase(str: string) {
  if (str.length === 0) return str;
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function snakeCaseToTitleCase(str: string) {
  return str.split("_").map(toTitleCase).join(" ");
}

export function toNumberWithCommas(num: number | string): string {
  const cleanedInput = num.toString().replace(/\D/g, "");
  // Format number with commas
  return cleanedInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
