import { XFormProps } from "./types";

/**
 * This component is a wrapper around the web form element. Since form
 * elements don't exist on mobile native, this component is a no-op on
 * mobile native (returns just the children). This means you'll need to
 * also handle the submit event separately on mobile native.
 *
 * @param param0
 * @param {React.ReactNode} param0.children - The form children
 * @param {boolean} [param0.noValidate] - Whether to disable browser validation
 * @param {(e: React.FormEvent<HTMLFormElement>) => void} param0.onSubmit - The submit handler
 * @returns
 */
export function XForm({ children, noValidate, onSubmit }: XFormProps) {
  return (
    <form noValidate={noValidate} onSubmit={onSubmit}>
      {children}
    </form>
  );
}
