import { XLogin } from "shared/x-pages/savvy-auth/x-login";
import type { NextPage } from "next";
import { useTrackImpression } from "shared/tracking/use-track-impression";

const Login: NextPage = () => {
  useTrackImpression({ pageName: "login" as const } as const);
  return <XLogin />;
};

export default Login;
