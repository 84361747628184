import { ReactComponentLike } from "prop-types";
import { XGuestGuard } from "shared/x-components/savvy-auth/x-guest-guard";

export function withGuestGuard<T>(Component: ReactComponentLike) {
  return (props: T) => (
    <XGuestGuard>
      <Component {...props} />
    </XGuestGuard>
  );
}
