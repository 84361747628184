/* eslint-disable no-restricted-imports */
import type {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  QueryTuple,
  TypedDocumentNode,
} from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum"; // TODO: SAV-3878 build react-native support
import { useEffect } from "react";
import { useAuth } from "shared/hooks/use-auth";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";

export function useLazyQueryWithErrorLogging<
  TVariables extends OperationVariables,
  TData = unknown
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  const resp = useLazyQuery(query, options);
  const advisorId = loggedInAsAdvisorID();
  const auth = useAuth();

  useEffect(() => {
    if (resp[1].error) {
      try {
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error not sure how to fix
          `${query.definitions[0]?.name?.value}
          error_name=${resp[1].error.name} 
          error_message=${resp[1].error.message}`
        );
      } catch (error) {
        console.error(error);
        datadogRum.addError(error, {
          user_id: auth.user?.id,
          logged_in_as_user_id: advisorId,
        });
      }
    }
  }, [resp[1].error]);
  return resp;
}
